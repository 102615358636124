import React, { useEffect, useState } from "react"
import { Flex, Box } from "@rebass/grid/emotion"
import styled from "@emotion/styled"

import theme from "../../../../../nutrafol-ui-kit/styles/theme"
import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"
import { ButtonWildMen } from "@nutrafol/nutrafol-ui-kit/dist/ButtonWildMen"
import { TextElementMen } from "@nutrafol/nutrafol-ui-kit/dist/TextElementMen"

import Logo from "../headervariant/logo"
import DownNav from "../../../../assets/icons/wild/wild-caret.svg"
import ArrowUp from "../../../../assets/icons/nova/arrow-up-brand-blue-20.svg"

const AltContentsContainer = styled(Flex)`
  display: flex;
  background-color: #fff;
  padding: 4px 4px;
  justify-content: flex-end;
  align-items: center;
  @media (min-width: 768px) {
    padding: 7px 16px;
  }
  @media (min-width: 1024px) {
    padding: 10px 24px;
  }

  .logo-wrap {
    padding: 0;
  }
`
const ButtonsWrap = styled(Flex)`
  margin-left: auto;
  justify-content: flex-end;
  button.c-btn {
    min-width: 100px !important;
    height: 44px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 4px !important;
    padding-right: 4px !important;
    @media (min-width: 374px) {
      min-width: 118px !important;
    }
    @media (min-width: 768px) {
      min-width: 133px !important;
    }
    .label {
      line-height: 44px !important;
    }
  }
`
const ButtonsHolder = styled.div`
  position: relative;
  width: calc(100vw - 101px);
  max-width: 268px;
  height: 44px;
  background: ${theme.wild.color.nova.base.solid[20]};
  border-radius: 4px;
  margin: 0 5px 0 0;
  padding: 0;
  @media (min-width: 1024px) {
    margin: 0 8px 0 0;
  }
`
const ButtonsFloat = styled.div`
  position: absolute;
  cursor: pointer;
  width: 100%;
  max-width: 268px;
  height: auto;
  top: 0;
  padding: 8px;
  background: ${theme.wild.color.men.base.secondary};
  box-shadow:
    0 0 20px rgba(0, 0, 0, 0.05),
    0 24px 38px rgba(0, 0, 0, 0.03),
    0 9px 46px rgba(0, 0, 0, 0.03),
    0 11px 15px rgba(0, 0, 0, 0.03);
  border-radius: 4px;
`
const OptionWrap = styled.button`
  width: 100%;
  max-width: 268px;
  height: 44px;
  line-height: 44px;
  background: ${theme.color.white};
  border-radius: 4px;
  border: 1px #c8c2d1 solid;

  span,
  svg {
    display: inline-block;
  }

  span {
    line-height: 28px !important;
    //border: 1px red dotted;
  }

  .c-title {
    text-align: left;
    width: 100%;
    padding: 0 0 0 4px;
    @media (min-width: 360px) {
      padding: 0 0 0 16px;
    }
  }

  .c-savings {
    color: ${theme.wild.color.men.base.black};
    background: ${theme.color.yellow};
    border-radius: 4px;
    padding: 2px 2px;
    text-align: center;
    height: 30px;
    transform: translate(0, -1px);

    &.no-savings {
      visibility: hidden;
    }
  }

  .c-strike {
    text-decoration: line-through;
    width: 32px;
  }

  .plus-icon {
    width: 42px;
    padding: 0;
    transform: rotate(180deg);
  }

  &.normal {
    border: none;
    background: ${theme.wild.color.men.base.secondary};
  }

  &.float {
    height: 42px;
    line-height: 42px;
    width: 100%;
    max-width: 252px;

    margin: 8px 0 0 0;

    .plus-icon {
      width: 4px;
      visibility: hidden;
      @media (min-width: 360px) {
        width: 16px;
      }
    }

    &.active {
      border: 1px ${theme.wild.color.men.brand.teal[80]} solid;
      background-color: ${theme.wild.color.men.brand.blue[20]};
    }
    &:hover,
    &:focus,
    &:focus-visible {
      // border: 1px #007a73 solid;
      // background-color: ${theme.wild.color.men.brand.blue[20]};
    }
  }
`

const Chooser = styled(Flex)`
  width: 100%;
  max-width: 252px;
  height: 48px;

  padding: 12px 0 0 0;

  span,
  svg {
    display: inline-block;
  }

  .c-choose {
    text-align: left;
    font-size: 12px !important;
  }

  .plus-icon {
    transform: translate(0, -1px);
    @media (min-width: 1024px) {
      transform: translate(0, -1px);
    }
  }
`

const FlexLine = styled(Flex)``

const ArrowBox = styled(Box)`
  display: none;
  @media (min-width: 768px) {
    display: block;
    height: 22px;
  }
`
const LogoBox = styled(Box)`
  display: none;
  @media (min-width: 1024px) {
    display: block;
    text-align: center;
    height: 22px;
  }
`

const atcText = "Add to cart"

const HeaderAtcVariant = (props) => {
  const { state } = props
  const { atcData, activeOption, setActiveOption } = state
  const [isFloat, setIsFloat] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const scrollToTop = () => {
    if (window["technology-wrapper"]) {
      //we need fake scroll to bypass GSAP pinpoints
      const fakeSmoothScroll = (targetPosition, duration = 500) => {
        const start =
          document.documentElement.scrollTop || document.body.scrollTop
        const change = targetPosition - start
        const startTime = performance.now()

        function animateScroll(timestamp) {
          const timeElapsed = timestamp - startTime
          const progress = Math.min(timeElapsed / duration, 1) // Progress from 0 to 1

          // Calculate the new scroll position
          const newPosition = start + change * progress
          document.documentElement.scrollTop = newPosition
          document.body.scrollTop = newPosition // For legacy support in older browsers

          // Continue the animation until the scroll reaches the target
          if (progress < 1) {
            requestAnimationFrame(animateScroll)
          }
        }

        // Start the animation
        requestAnimationFrame(animateScroll)
      }

      // Usage Example: Scroll to top smoothly
      fakeSmoothScroll(0) // Scroll to top with 1000ms duration
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    }

  }
  const handleClick = () => {
    if (isFloat) {
      setIsFloat(false)
    }
  }
  useEffect(() => {
    if (typeof document !== "undefined") {
      const handleKey = function (evt) {
        evt = evt || window.event
        var isEscape =
          evt &&
          (evt.key === "Escape" || evt.key === "Esc" || evt.keyCode === 27)
        if (isEscape) {
          if (isFloat) {
            setIsFloat(false)

            const header = document.querySelector("header")
            const main = document.querySelector("main")
            const footer = document.querySelector("footer")

            header && header.setAttribute("data-mousedown", "1")
            main && main.setAttribute("data-mousedown", "1")
            footer && footer.setAttribute("data-mousedown", "1")
          }
        }
      }
      document.addEventListener("click", handleClick)
      document.addEventListener("keydown", handleKey)
      return () => {
        document.removeEventListener("click", handleClick)
        document.removeEventListener("keydown", handleKey)
      }
    }
  }, [isFloat])

  if (atcData === null) {
    return null
  }

  const options = atcData.options

  const atcClick = () => {
    if (isSubmitted) {
      return
    }
    const option = options[activeOption]
    setIsSubmitted(true)
    segmentEvent("Product Added", {
      text: atcText,
      name: atcData.title,
      sku: option.sku,
      price: option.price,
      variant: option?.ui_data?.title,
      product_gender: option.product_gender,
      billing_interval: option.billing_interval,
      core_category: atcData.coreCategory,
      product_category: option.product_category,
      purchase_type: option.purchase_type,
      shipping_interval: option.shipping_interval,
      quantity: 1,
      quiz_reco: false,
      source: "pdp",
      type: "button_stickynav",
    })
    window.location.href =
      process.env.GATSBY_MAGENTO_URL +
      `dpa/add/tocart/?skus=` +
      option.sku +
      `&purchase_source=${atcData.purchase_source || atcData.source || "pdp"}`
  }

  const onSelect = (idx) => {
    const option = options[idx]
    segmentEvent("Product Selected", {
      variant: option?.ui_data?.title,
      core_category: atcData.coreCategory,
      name: atcData.title,
      sku: option.sku,
      price: option.price,
      product_gender: option.product_gender,
      type: "dropdown_stickynav",
    })
  }

  const onOpen = () => {
    const option = options[activeOption]
    segmentEvent("Product Dropdown Viewed", {
      image_url: atcData.mainimg,
      name: atcData.title,
      product_gender: option.product_gender,
      type: "dropdown_stickynav",
    })
  }

  const renderOption = (idx, type = "normal") => {
    const option = options && options[idx] ? options[idx] : null

    if (!option) {
      return null
    }

    return (
      <OptionWrap
        key={idx}
        className={`${type} ${
          activeOption === idx ? "active" : ""
        } header-atc-option-chooser header-atc-option-${idx}`}
        onClick={(e) => {
          if (type === "float") {
            setActiveOption(idx)
            onSelect(idx)
          } else {
            e.nativeEvent.stopImmediatePropagation()
            setIsFloat(true)
            onOpen()
          }
        }}
      >
        <FlexLine>
          <Box width={[0.65]}>
            <TextElementMen
              element="span"
              text={option?.ui_data?.title}
              className="body--regular-b c-title text-men-secondary-secondary"
            />
          </Box>
          <Flex width={[0.35]} justifyContent={`flex-end`}>
            <Box pr={["4px"]}>
              <TextElementMen
                element="span"
                aria-label={
                  option?.old_price && option?.old_price !== option.price
                    ? `Was ${option.old_price} dollars, now discounted`
                    : ``
                }
                text={
                  option?.old_price && option?.old_price !== option.price
                    ? `$${option?.old_price}`
                    : ``
                }
                className="body--regular c-strike text-men-base-disabled"
              />
            </Box>
            <Box>
              <TextElementMen
                element="span"
                text={`$${option.price}`}
                className="body--regular-b c-price text-men-secondary-secondary"
              />
            </Box>
            <Box>
              <DownNav className="plus-icon" />
            </Box>
          </Flex>
        </FlexLine>
      </OptionWrap>
    )
  }

  const mapper =
    options && options.map
      ? options.map((el, idx) => renderOption(idx, "float"))
      : null

  return (
    <AltContentsContainer>
      <ArrowBox width={[0, 0, 0.4, "calc(50% - 80px)"]}>
        <button onClick={scrollToTop}>
          <ArrowUp />
        </button>
      </ArrowBox>
      <LogoBox width={[0, 0, 0, 160]}>
        <Logo />
      </LogoBox>
      <ButtonsWrap width={[1, 1, 0.6, "calc(50% - 80px)"]}>
        <ButtonsHolder>
          {renderOption(activeOption)}
          {isFloat && (
            <ButtonsFloat>
              <Chooser>
                <Box flex={1}>
                  <TextElementMen
                    element="span"
                    text={`Choose an Option`}
                    className="mono--mini uppercase c-choose text-men-secondary-secondary"
                  />
                </Box>
                <Box>
                  <DownNav className="plus-icon" />
                </Box>
              </Chooser>
              {mapper}
            </ButtonsFloat>
          )}
        </ButtonsHolder>
        <ButtonWildMen
          className={`c-btn uppercase ${isSubmitted ? "disabled" : ""}`}
          primary
          label={atcText}
          onClick={atcClick}
        />
      </ButtonsWrap>
    </AltContentsContainer>
  )
}

export default HeaderAtcVariant
